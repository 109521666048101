import axios from 'axios'

export default {
  actions: {
    xhttp ({ commit }, payload) {
      const httpRequest = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Accept': 'application/json'
        },
        url: null,
        method: null,
        data: null,
        params: null,
        baseURL: process.env.NODE_ENV === 'development' ? 'http://local-api.aiinfeed.com' : 'https://api.aiinfeed.com',
        responseType: 'json',
        responseEncoding: 'utf8'
      }
      let session = JSON.parse(localStorage.getItem('cdpUser'))
      if (session) {
        httpRequest.headers.Authorization = 'Bearer ' + session.accessToken
      }
      httpRequest.url = (payload.url ? payload.url : null)
      httpRequest.method = (payload.method ? payload.method : null)
      httpRequest.data = (payload.data ? payload.data : null)
      httpRequest.params = (payload.params ? payload.params : null)
      httpRequest.headers = (payload.headers ? payload.headers : httpRequest.headers)
      httpRequest.commit = commit
      const resp = axios(httpRequest)
      resp.catch((error) => {
        let resp = error.response
        if (process.env.NODE_ENV === 'development') {
          console.log('# [AIIN_FEED::DEV]', resp.status, resp.data)
        }

        let data = resp.data
        if ('success' in data) {
          if (resp.status === 401) {
            payload.self.$router.push({ 'name': 'UserLogin' })
          } else if ('exception' in data) {
            if (data.success && data.exception === 'CustomException') {
              if (process.env.NODE_ENV === 'development') {
                console.log('#CustomException => ', data.message)
              }
              payload.self.snack('warning', data.message)
            }
          }
          // 이 catch() 절의 실행 여부를 Flagging
          error.catchPreProcessed = true
        }

        return error
      })

      return resp
    }
  }
}
